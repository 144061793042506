<template>
  <!-- <div>
    <a href="javascript:;" class="login_type_2" @click="getGoogle">
      <img class="facebook" src="../assets/img/google.png" />
      <div>google</div>
    </a>
  </div> -->
  <div>
    <div class="g_id_signin2" id="g_id_signin2"></div>
  </div>
</template>
<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    client_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // clientId: "",
      clientIdd: "",
    };
  },
  computed: {
    clientId() {
      return this.$store.state.client_id;
    },
  },
  watch: {
    clientId(newVal, oldVal) {
      if (newVal) {
        this.clientIdd = newVal;
        this.initGoogle();
      }
    },
  },
  created() {},
  mounted() {
    // this.init(this.handleCredentialResponse);
  },
  methods: {
    initGoogle() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.init(this.handleCredentialResponse);
        }, 250);
      });
    },
    init(fn) {
      // window.addEventListener("load", this.gload(fn));
      this.gload(fn);
    },
    gload(fn) {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          // 主要就是填写client_id
          client_id: this.clientId,
          callback: fn,
          locale: "en-US",
        });
        window.google.accounts.id.renderButton(
          document.getElementById("g_id_signin2"),
          {
            theme: "filled_blue",
            size: "large",
            shape: "circle",
            // width: '336',
            type: "icon",
            text: "signin_with",
          }
        );
      }
    },
    decodeJwtResponse(token) {
      const strings = token.split(".");
      return JSON.parse(
        decodeURIComponent(
          escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))
        )
      );
    },
    async handleCredentialResponse(response) {
      if (response) {
        // const response1 = this.decodeJwtResponse(response)
        // const responsePayload = this.decodeJwtResponse(response.credential);
        // console.log(response);
        // console.log('responsePayload', responsePayload);
        // console.log("ID: " + responsePayload.sub);
        // console.log('Full Name: ' + responsePayload.name);
        // console.log('Given Name: ' + responsePayload.given_name);
        // console.log('Family Name: ' + responsePayload.family_name);
        // console.log("Image URL: " + responsePayload.picture);
        // console.log("Email: " + responsePayload.email);
        let guestInfo = JSON.parse(localStorage.getItem("guestInfo"));
        let hash = window.location.pathname;
        if (hash.includes("login")) {
          this.Login(response.credential);
        } else {
          this.guestLogin(response.credential);
        }
      }
    },
    Login(access_token) {
      let game_id = this.$route.query.g;
      let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
      let params = {
        game_id: localStorage.getItem("game_id"),
        device_id: webUUID,
        third_platform: 2,
        third_account: this.clientIdd || this.clientId,
        third_account_token: access_token,
      };
      this.$http.post("/apis/h5/thirdLogin", params).then((res) => {
        if (res.code === 200) {
          switch (Number(params.game_id)) {
            case 121:
              Adjust.trackEvent({
                eventToken: "wjzkta",
              });
              break;
            default:
              Adjust.trackEvent({
                eventToken: "4a7k78",
              });
              break;
          }

          if (res.data.isReg !== "0") {
            switch (Number(params.game_id)) {
              case 121:
                Adjust.trackEvent({
                  eventToken: "dibvk8",
                });
                break;
              default:
                Adjust.trackEvent({
                  eventToken: "4j8tcm",
                });
                Adjust.trackEvent({
                  eventToken: "2mv6rq",
                });
                break;
            }
          }
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userid", res.data.userid);
          localStorage.setItem("iframeURL", res.data.login_url);
          localStorage.removeItem("guestInfo");
          this.$store.state.token = res.data.token;
          this.$store.state.iframeURL = res.data.login_url;
          // this.$router.push("/main");
          this.$router.push("/main/my");
          this.$store.state.sdkShow = false;
          this.$store.commit("openSdk", false);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
    guestLogin(access_token) {
      let game_id = this.$route.query.g;
      let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
      let userid = localStorage.getItem("userid");
      let token = localStorage.getItem("token");
      let params = {
        game_id: localStorage.getItem("game_id"),
        device_id: webUUID,
        third_platform: 2,
        third_account: this.clientIdd || this.clientId,
        third_account_token: access_token,
        userid,
        token,
      };
      this.$http.post("/apis/h5/thirdUpgrade", params).then((res) => {
        if (res.code === 200) {
          switch (Number(params.game_id)) {
            case 121:
              Adjust.trackEvent({
                eventToken: "wjzkta",
              });
              break;
            default:
              Adjust.trackEvent({
                eventToken: "4a7k78",
              });
              break;
          }

          if (res.data.isReg !== "0") {
            switch (Number(params.game_id)) {
              case 121:
                Adjust.trackEvent({
                  eventToken: "dibvk8",
                });
                break;
              default:
                Adjust.trackEvent({
                  eventToken: "4j8tcm",
                });
                Adjust.trackEvent({
                  eventToken: "2mv6rq",
                });
                break;
            }
          }
          localStorage.removeItem("token");
          localStorage.removeItem("userid");
          localStorage.removeItem("iframeURL");
          localStorage.removeItem("guestInfo");
          this.$store.state.guest = true;
          this.$store.state.token = "";
          this.$store.state.iframeURL = "";
          this.$router.push({
            path: "/login",
            query: { game_id: localStorage.getItem("game_id") },
          });
          this.$store.commit("openSdk", false);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
    handleClick() {
      console.log(this.clientId);
    },
  },
  beforeDestroy() {
    window.removeEventListener("load", this.gload());
  },
};
</script>

<style scoped lang="scss">
.g_id_signin2 {
  width: 40px;
  height: 40px;
}
</style>
