import { createApp } from 'vue'
import App from './App.vue'
// 自适应配置
import './utils/rem.js'
// 公共样式
import './assets/css/index.scss'
// 全局配置信息
import config from './utils/config'
// 路由配置
import router from './router'
// vuex 全局状态管理
import store from './store'
// 接口配置
import axios from "./utils/api";
// 语言国际化
import I18n from './i18n'
// 公共方法
import utils from './utils'
//谷歌登录
import GSignInButton from 'vue-google-signin-button'
//引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
// 全局组件
import SvgIcon from './components/svgIcon.vue'
import Navbar from './components/navbar.vue'
import NoData from './components/noData.vue'

const app = createApp(App)

const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("./assets", true, /.svg$/);
requireAll(req);

app.use(store)
app.use(router)
app.use(I18n)
app.use(Vant)
app.use(GSignInButton)
app.component('SvgIcon', SvgIcon)
app.component('Navbar', Navbar)
app.component('NoData', NoData)
app.config.globalProperties.$utils = utils
app.config.globalProperties.$Msg = utils.Msg
app.config.globalProperties.$config = config
app.config.globalProperties.$http = axios
app.config.globalProperties.$i18n = I18n
app.config.globalProperties.$isLaunchedFromHomeScreen = isLaunchedFromHomeScreen()

let appToken = {
  3: '5ajnk6ecvaps',
  120: '5ajnk6ecvaps',
  121: '1z2u84jnw90g',
}
setTimeout(() => {
  Adjust.initSdk({
    appToken: appToken[localStorage.getItem("game_id")] || '5ajnk6ecvaps',
    // appToken: '5ajnk6ecvaps',
    environment: "production",
    defaultTracker: Number(localStorage.getItem("game_id")) === 121 ? "1fn7i84t" : '',
  });
}, 150);

function isLaunchedFromHomeScreen() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /safari/.test(userAgent) && !/chrome/.test(userAgent);
}


router.beforeEach((to, from, next) => {
  //将hash路由模式切换成history模式获取gameID
  const match = to.fullPath.match(/game_id=(\d+)/);
  const gameId = match ? match[1] : null;
  if (gameId) {
    localStorage.setItem("game_id", gameId)
  }
  let token = localStorage.getItem("token") || ""; //在本地存储中获取token
  let game_id = localStorage.getItem("game_id") || ""
  if (token) {
    //判断是否有token
    if (to.query.game_id && to.query.game_id !== game_id) {
      localStorage.removeItem("token")
      localStorage.removeItem("userid");
      localStorage.removeItem("iframeURL");
      store.state.token = "";
      store.state.iframeURL = "";
      next({ path: "/login", query: { game_id: to.query.game_id } }); //跳转页面到login页
    } else {
      next()
    }
  } else {
    //在没有token的前提下，to下面的path是否为/login，如果不是则页面跳转到登录页面
    if (to.path == "/login") {
      next();
    } else {
      if (to.path == "/login/setPassword") {
        next();
      } else if (to.path == "/login/register") {
        next();
      } else {
        next({ path: "/login", query: { game_id: game_id } }); //跳转页面到login页
      }

    }
  }
});


app.mount('#app')
